const { Init, RenderWidget, ServiceLocator, CommonServices, ApplicationError } = require("bwtk");
export const container = document.getElementById("maincontent");

// We needthis for Omniture:
const _connectData = { store: null, localization: null };

export function initialize(config: any, globalActionListner: Function) {

    config = Object.assign({}, config, {
        "loader.staticWidgetMappings": {
            "buyflow-components": {
                "namespace": "Ordering/BRS",
                "factory": () => require("buyflow-components")
            },
            "brs-buyflow-navigation": {
                "namespace": "Ordering/BRS",
                "factory": () => require("brs-buyflow-navigation")
            },
            "brs-buyflow-configuration": {
                "namespace": "Ordering/BRS",
                "factory": () => require("brs-buyflow-configuration")
            },
            "brs-buyflow-qualification": {
                "namespace": "Ordering/BRS",
                "factory": () => require("brs-buyflow-qualification"),
            },
            "brs-buyflow-checkout": {
                "namespace": "Ordering/BRS",
                "factory": () => require("brs-buyflow-checkout")
            }
        }
    });
    Init(config);

    const serviceLocator = ServiceLocator.instance;
    const store = _connectData.store = serviceLocator.getService(CommonServices.Store);
    const localization = _connectData.localization = serviceLocator.getService(CommonServices.Localization);

    localization.preloadLocaleData({
        "brs-buyflow-navigation": "Ordering/BRS/brs-buyflow-navigation",
        "brs-buyflow-configuration": "Ordering/BRS/brs-buyflow-configuration",
        "brs-buyflow-qualification": "Ordering/BRS/brs-buyflow-qualification",
        "brs-buyflow-checkout": "Ordering/BRS/brs-buyflow-checkout"
    }).then((locales: any) => {
        RenderWidget("brs-buyflow-navigation", container);
    }).catch((errors: any) => store.dispatch({
        type: "WIDGET_ERROR",
        payload: new ApplicationError("Localization request failed!")
    }));

    store.createGlobalActionListener(globalActionListner);
}

export function omnitureConnector() {
    return _connectData;
}

/**
 * adds value to the
 * config properties and map
 * to the path/namespace
 * @param json
 * @returns
 */
export function jsonToConfig(json: any) {
    const NODE_TYPE = {
        VALUE: 0,
        NAMESPACE: 1,
        WIDGET: 2
    };
    const getNodeType = (key: string) => {
        switch (true) {
            case /\/$/.test(key): return NODE_TYPE.NAMESPACE;
            case /:$/.test(key): return NODE_TYPE.WIDGET;
            default: return NODE_TYPE.VALUE;
        }
    };
    const Reducer = (collection: any, root: any = null, scope: any = {}) => {
        const keys = Object.keys(collection);
        let $scope = { ...scope };
        let $path = root || "";
        let _config: any = {};
        keys.forEach(key => {
            switch (getNodeType(key)) {
                case NODE_TYPE.NAMESPACE: _config = { ..._config, ...Reducer(collection[key], `${$path}${key}`, $scope) };
                    break;
                case NODE_TYPE.WIDGET:
                    const $params = { ...$scope, ...collection[key] };
                    Object.keys($params).forEach((param: any) => _config[`${$path}${key.slice(0, -1)}/${param}`] = $params[param]);
                    break;
                default:
                case NODE_TYPE.VALUE: $scope[key] = collection[key];
            }
        });
        return _config;
    };
    return Reducer(json);
}
