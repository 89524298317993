(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bwtk"), require("react"), require("react-intl"), require("react-redux"), require("redux-actions"), require("react-router-dom"), require("react-dom"), require("redux"), require("redux-observable"), require("react-slick"));
	else if(typeof define === 'function' && define.amd)
		define(["bwtk", "react", "react-intl", "react-redux", "redux-actions", "react-router-dom", "react-dom", "redux", "redux-observable", "react-slick"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("bwtk"), require("react"), require("react-intl"), require("react-redux"), require("redux-actions"), require("react-router-dom"), require("react-dom"), require("redux"), require("redux-observable"), require("react-slick")) : factory(root["bwtk"], root["React"], root["ReactIntl"], root["ReactRedux"], root["ReduxActions"], root["ReactRouterDOM"], root["ReactDOM"], root["Redux"], root["ReduxObservable"], root["ReactSlick"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__161__, __WEBPACK_EXTERNAL_MODULE__163__, __WEBPACK_EXTERNAL_MODULE__164__, __WEBPACK_EXTERNAL_MODULE__166__) => {
return 